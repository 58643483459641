// 
// 
// processes.scss
//
//

.process-circle{
  width: $spacer*2;
  height: $spacer*2;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  &:after{
    content: '';
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
  }
  @each $name, $color in $theme-color-scheme {
    &.bg-#{$name}{
      background: rgba($color,.05) !important;
      &:after{
        background-color: $color;
      }
    }
  }
}

.process-vertical{
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  li{
    display: flex;
    align-items: center;
    .process-circle{
      margin-right: $spacer;
    }
    &:not(:last-child){
      position: relative;
      margin-bottom: $spacer;
    }
  }
}

@include media-breakpoint-up(md){
  .process-vertical{
    li{
      width: 50%;
      margin-left: 50%;
      .process-circle{
        margin-left: -$spacer;
      }
      &:nth-child(even){
        flex-direction: row-reverse;
        text-align: right;
        margin-left: 0;
        margin-right: 50%;
        .process-circle{
          margin-right: -$spacer;
          margin-left: $spacer;
        }
      }
      &:not(:last-child){
        padding-bottom: $spacer*3;
        margin-bottom: 0;
        &:after{
          content: '';
          display: block;
          width: $border-width;
          height: $spacer*2;
          background: $border-color;
          position: absolute;
          top: 4.125rem;
        }
      }
    }
  }
  .text-light{
    .process-vertical{
      li{
        &:not(:last-child){
          &:after{
            background: rgba($white,.25);
          }
        }
      }
    }
  }
}